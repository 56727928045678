<!-- 组织架构 -->

<template>
	<div class="h-full ob-t">
		<div class="organization-box">
			<el-input class="tree-search" v-model="filterText" show-word-limit placeholder="请输入菜单/功能" />
			<!-- <div style=" background-color:#f3f3f3">
				<div class="menu-type">
					<el-tabs slot="reserve" class="h-table-switch" v-model="platformType" @tab-click="handleClick">
						<el-tab-pane label="管理" name="1"></el-tab-pane>
						<el-tab-pane label="租户" name="2"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="menu-add">
					<span @click="addTree" >
						<span>&nbsp;</span><i class="el-icon-plus"></i>
					</span>
				</div>
			</div> -->
			<div class="scroll-bar item">
				<PubliceTree
					ref="customTree"
					:itemShow="itemShow"
					:tree-data="treeData"
					:tree-expand-all="treeExpandAll"
					:tree-node-key="treeNodeKey"
					:treeExpandedKeys="treeExpandedKeys"
					@handleNodeClick="handleNodeClick"
					@addItem="addTreeItem"
					@deleteItem="deleteTreeItem"
					@editItem="editTreeItem"
					:checkedID="treeCheckedID"
				></PubliceTree>
			</div>
		</div>

		<fixed-list  v-show="!config.isDetailDisplay" :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
			<div class="h-handle-button" slot="buttonGroup">
				<div class="h-b">
					<el-button type="primary" size="small" @click="addPermission" v-right-code="'SysMenu:Edit'">新增</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="sysLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
					<el-table-column
						v-for="(col, index) in dataSource.ColDefs.BodyFieldParams"
						:key="index"
						:prop="col.FieldName"
						:label="col.DisplayName"
						:render-header="bindFilter(queryParam, col)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="!config || !config.isDetailDisplay ? parseInt(col.Width) : 0"
						v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))"
					>
						<template slot-scope="scope">
							<span v-if="col.FieldName === 'PermName'">
								<span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span>
							</span>
							<span v-else>{{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" :min-width="150" v-if="!config.isDetailDisplay">
						<template slot-scope="scope">
							<el-button @click="deleteP(scope.row)" size="small" type="text" v-right-code="'Sysmenu:Delete'">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>

		<DialogEx :options="addOrgOptions" title="资源编辑" @onOk="handleOrgOk">
			<AddTask ref="addOrg" :dataSource="orgData"></AddTask>
		</DialogEx>
	</div>
</template>

<script>
export default {
	mounted() {
		this.onPageChange();
		this.getTreeselect();
		this.Utils.lazy(() => {
			this.Event.$on('reloadPageOrgList', () => this.getTreeselect());
			this.Event.$on('reloadMenuPageList', () => this.onPageChange());
			this.Event.$on('onAddUser', () => this.addPermission());
		});
	},
	data() {
		return {
			BodyFieldParams: [],
			queryParam: {
				PageIndex: 1,
				PageSize: 10
			},
			multipleSelection: [],
			dataSource: {
				ColDefs: {},
				Result: [],
				TotalCount: 0
			},
			checked: false,
			itemShow: true,
			treeExpandAll: false,
			refreshTable: true,
			treeNodeKey: 'SysMenuId',
			treeData: [],
			treeExpandedKeys:[],
			addOrgOptions: {
				visible: false,
				width: '60%',
				modal: true
			},
			orgData: {},
			searchContent: '',
			selectTreeNode: null,
			addPwdOptions: {
				visible: false,
				width: '40%',
				modal: true
			},
			pwdData: {
				id: ''
			},
			filterText: '',
			treeCheckedID: '',
            platformType:2
		};
	},
	props: {
		config: {
			isDetailDisplay: false,
			isButtonlDisplay: false
		},
		option: {}
	},
	watch: {
		filterText(val) {
			this.$refs.customTree.$refs.tree.filter(val);
		}
	},
	methods: {
		// 新增
		addPermission() {
			var _this = this;
			_this.$ajax.send('omsapi/syspermission/init', 'get', {}, data => {
				data.Result.treeList = JSON.parse(JSON.stringify(_this.treeData));
				//初始化订单类型
				var dataSource = data.Result;
				if(dataSource){
					dataSource.PlatformType = parseInt(_this.platformType);
				}
				_this.onChangeEditDataSource(data.Result);
			});
		},
		setPwd: function(val) {
			this.addPwdOptions.visible = true;
			this.pwdData.id = val.id;
		},
		// 编辑
		rowClick(val) {
			this.$ajax.send(
				'omsapi/syspermission/getbyid',
				'get',{ id: val.SysPermissionId },
				data => {
					this.Event.$emit('clearEditUserForm');
					data.Result.treeList = JSON.parse(JSON.stringify(this.treeData));
					this.onChangeEditDataSource(data.Result);
				}
			);
			this.onPageChange();
		},
		checkRightCode(code)
		{
		  return this.Utils.checkRightCode(code,this.$store.state.global.pageRightCodes);
		},
		// 删除
		delInfo(data) {
			this.$confirm('确认要删除当前用户 ' + data.userName + ' 吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
					var _this = this;
					_this.$ajax.send('system/sysuser/delete?id=' + data.id, 'get', {}, result => {
						// _this.getTreeselect();
						this.getList();
					});
				})
				.catch(() => {});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			this.$emit('onCheckRow', this.multipleSelection);
		},
		/* 搜索按钮点击操作 */
		search(data) {
			this.queryParam = { ...data };
			this.onPageChange();
		},
		onPageChange() {
			var _this = this;
			_this.getList();
		},
		//列表查询
		getList: function() {
			let params = this.queryParam;
			if(params.Params==null||params.Params==undefined){
			  params.Params={};
				
			}
			this.$ajax.query('omsapi/syspermission/getpagelist', 'post', params, result => {
				this.dataSource = result;
			});
		},
		onDataSourceChange(ds) {
			this.BodyFieldParams = ds;
		},

		onChangeEditDataSource(data) {
			this.config.isDetailDisplay = true;
			this.$emit('onChangeEditDataSource', data);
		},

		resetQuery() {
			this.getTreeselect();
		},
		//  查询左侧tree下拉树结构
		getTreeselect() {
      		var _this = this;
			  _this.$ajax.send('omsapi/sysmenu/getrecursionlist', 'get', {platformType:parseInt(_this.platformType)}, data => {
				_this.treeData = data.Result;
				_this.treeCheckedID = _this.treeData[0].Id;
				_this.selectTreeNode = _this.treeData[0];
			});
		},
		// 展开全部
		getTask() {
			this.refreshTable = false;
			this.treeExpandAll = !this.treeExpandAll;
			this.$nextTick(() => {
				this.refreshTable = true;
			});
		},
		// 选中每一个子节点---对应右边明细
		handleNodeClick(data) {
			this.selectTreeNode = data;
			this.queryParam.Params.SysMenuId = data.SysMenuId;
			this.getList();
		},
		// 弹框------------------------新增表单数据
		addData(data) {
			this.getTreeselect();
			this.clean();
		},
		// 弹框-------------------------修改表单数据
		editData(data) {
			this.$refs.customTree.treeEditItem(data);
			this.handleNodeClick(data);
		},
		addTree() {
			this.addTreeItem(null);
		},
		// ----------------增加树节点设置默认父级节点
		addTreeItem(data) {
			var _this = this;
			_this.addOrgOptions.visible = true;
			var parentId = 0;
			if (data != null)
				parentId = data.SysMenuId;
			_this.$ajax.send('omsapi/sysmenu/init', 'get', {ParentId:parentId}, result => {
				// if (data != null) {
				// 	result.Result.ParentId = data.SysMenuId;
				// 	result.Result.ParentName = data.MenuName;
				// } else {
				// 	result.Result.ParentId = null;
				// 	result.Result.ParentName = '顶级';
				// }
				//  result.Result.PlatformType=this.platformType;
				_this.orgData = result.Result;
				_this.addOrgOptions.visible = true;
			});
		},
		// ----------------修改树节点
		editTreeItem(data) {
			var _this = this;
			_this.$ajax.send('omsapi/sysmenu/getbyid', 'get', { id: data.SysMenuId }, result => {
				result.Result.isEnabled = result.Result.isEnabled + '';
				_this.orgData = result.Result;
				_this.addOrgOptions.visible = true;
			});
		},
		// ---删除树节点
		deleteTreeItem(data) {
			this.$confirm('确认要删除当前节点 ' + data.MenuName + ' 吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				var _this = this;
				_this.$ajax.send('omsapi/sysmenu/delete?id='+data.SysMenuId, 'post', {id:data.SysMenuId}, result => {
					_this.getTreeselect();
				});
			})
			.catch(() => {});
		},
		handleDrop(draggingNode, dropNode, dropType, ev) {
			var id = 0;
			switch (dropType) {
				case 'before':
					id = dropNode.data.parentId;
					break;
				case 'after':
					id = dropNode.data.parentId;
					break;
				case 'inner':
					id = dropNode.data.id;
					break;
				default:
					return;
			}
			if (id == 0 || id == null) {
				this.Utils.messageBox('组织部门只允许有一个根节点.', 'error');
				this.getTreeselect();
				return;
			}

			draggingNode.data.parentId = id;
			var list = [];
			this.getBrotherNodes(this.treeData, draggingNode.data.parentId, list);

			/// console.log('list', list);
			console.log('draggingNode.data.parentId', draggingNode.data.parentId);

			var _this = this;
			_this.$ajax.send('system/sysorganization/adddragdata', 'post', list, result => {
				_this.getTreeselect();
			});
		},

		//查找子节点
		getBrotherNodes(list, id, bList) {
			// for (let i in list) {
			// 	if (list[i].parentId === id) {
			// 		bList.push(list[i]);
			// 	}
			// 	if (list[i].children?.length > 0) {
			// 		this.getBrotherNodes(list[i].children, id, bList);
			// 	}
			// }
		},

		handleOrgOk: function(option) {
			this.$refs.addOrg.saveMenu(option);
		},
		handlePwdOk: function(option) {
			this.$refs.addPwd.changePwd(option);
		},
		handleClick(tab, event) {
			this.getTreeselect();
			this.getList();
		},
		deleteP:function(row){
			this.$confirm('确认要删除权限 \'' + row.PermName + '\' 吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$ajax.send('omsapi/syspermission/delete?id='+row.SysPermissionId, 'post', [row.SysPermissionId], result => {
					this.getList();
				});
			}).catch(() => {
			});
		},
	},
	components: {
		"PubliceTree": resolve => { require(['./tree.vue'], resolve) },
		"AddTask": resolve => { require(['./editToMenu.vue'], resolve) }
	}
};
</script>

<style>
.ob-t {
	display: flex;
}

.ob-t .h-table-list {
	padding-top: 10px;
}

.organization-box {
	/* background-color: #fbfbfb; */
	border: 1px solid #e5e6eb;
	width: 250px;
	display: flex;
	flex-direction: column;
}
.menu-type{
	width:180px; float:left
}
.menu-type .el-tabs__item{
	padding: 0 15px !important;
}
.menu-add{
	width: 36px;
    height: 24px;
	line-height: 24px;
    float: right;
    text-align: center;
    border-bottom: solid 1px #e0e0e0;
	cursor: pointer;
}
.organization-box .btn-add {
	background-color: #f5f7fa;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin: 0 4% 10px; */
	padding: 0 15px;
	color: #666;
	cursor: pointer;
}

.organization-box .btn-add i {
	font-size: 12px;
	font-weight: bold;
	color: #333;
}

.organization-box .tree-search {
	width: 92%;
	margin: 10px 4%;
}

.organization-box .scroll-bar {
	flex: 1;
	height: 0;
}

.organization-box .white-body-view {
	height: 100%;
	overflow-y: auto;
}

.organization-box .el-tree {
	background-color: transparent;
}

.organization-box .el-tree .el-tree-node__content {
	display: flex;
	flex-direction: row;
	height: 36px;
}

.organization-box .el-tree .custom-tree-node {
	font-size: 12px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
}

.organization-box .el-tree .custom-tree-node * {
	font-size: inherit;
}

.organization-box .el-card__body {
	padding: 0px;

	overflow-y: auto;
}


</style>
